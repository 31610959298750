// https://github.com/samhirtarif/react-audio-player
import React, { useEffect, useRef } from "react";
import { AudioPlayer } from "react-audio-player-component";
import { FiDownload } from "react-icons/fi";
import { Box, Flex, Text, IconButton, useColorModeValue } from "@chakra-ui/react";

const MusicCard = ({ url, index }) => {
  const audioPlayerRef = useRef(null);

  const getFileName = (url) => {
    try {
      const urlParts = url.split('/');
      let fileName = urlParts[urlParts.length - 1];
      // Remove any query parameters but keep the extension
      fileName = fileName.split('?')[0];
      // Decode URI-encoded characters
      fileName = decodeURIComponent(fileName);
      return fileName;
    } catch (error) {
      return 'audio.mp3';
    }
  };

  const handleDownload = async (downloadableUrl) => {
    try {
      const response = await fetch(downloadableUrl);
      if (!response.ok) {
        throw new Error("Failed to download the file");
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const fileName = getFileName(downloadableUrl);
      
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error("An error occurred while downloading the file:", error);
    }
  };

  useEffect(() => {
    let isMuted = true;
    const elem = document?.querySelectorAll("#singleMusic div");
    elem.forEach((el) => {
      if (isMuted && el) {
        el.style.padding = "0px";
      }
    });
    return () => {
      isMuted = false;
    };
  }, []);

  const bgColor = useColorModeValue("white", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const textColor = useColorModeValue("gray.500", "gray.300");

  return (
    <Box
      id="singleMusic"
      position="relative"
      p={6}
      minH="200px"
      transition="all 0.15s"
      borderRadius="xl"
      boxShadow="sm"
      border="1px solid"
      borderColor={borderColor}
      bg={bgColor}
      minW="200px"
      w="400px"
      _hover={{
        boxShadow: "lg",
        borderColor: "gray.300",
      }}
      display="flex"
      flexDir="column"
    >
      <Flex justify="space-between" flex="1">
        <Text color={textColor} noOfLines={1} flex="1" mr={4}>
          {getFileName(url)}
        </Text>
        <IconButton
          icon={<FiDownload />}
          aria-label="Download"
          variant="ghost"
          color={textColor}
          size="sm"
          onClick={() => handleDownload(url)}
        />
      </Flex>

      <Box>
        <AudioPlayer
          ref={audioPlayerRef}
          src={url}
          minimal={false}
          width={"200"}
          trackHeight={75}
          barWidth={1}
          gap={2}
          visualise={true}
          backgroundColor="transparent"
          barColor="#C1D0B5"
          barPlayedColor="red"
          skipDuration={2}
          showLoopOption={true}
          showVolumeControl={true}
          hideTrackKnobWhenPlaying={true}
          onloadstart={(e) => {
            audioPlayerRef.current = e.target;
          }}
        />
      </Box>

      <style jsx>{`
        #singleMusic div {
          padding: 0px !important;
          padding-left: 0px !important;
          padding-right: 0px !important;
        }
       
      `}</style>
    </Box>
  );
};

export default MusicCard;
