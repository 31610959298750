import ChatPromtPagination from "components/ChatPromtPagination";
import useMediaQuery from "components/useMediaQuery";
import { userPublicRequest } from "config/axios.config";
import { AuthContext } from "contexts/AuthContext";
import { ConfigContext } from "contexts/ConfigContext";
import useCustomHistory from "langHoc/useCustomHistory";
import { Fade, Grid, Slug } from "mauerwerk";
import React, { useContext, useEffect, useState, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineClose } from "react-icons/ai";
import { BsPin } from "react-icons/bs";
import NativeMeClone from "./NativeMeClone";
import { BiArrowBack } from "react-icons/bi";
import { FiArrowLeft } from "react-icons/fi";
import { Badge, Box } from "@chakra-ui/react";
import PromptModal from "layouts/HomeNew/Search/PromptModal";

import AssistantModal from "layouts/HomeNew/Search/AssistantModal";
import ParsedContent from "components/new/ParsedContent";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import { ModalContext } from "contexts/ModalContext";
import { SessionListContext } from "contexts/SessionListContext";
import urlParser from "url";
import dayjs from "dayjs";
function MyComponent({ data }) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(data, "text/html");

  Array.from(doc.getElementsByTagName("img")).forEach((img) => {
    img.parentNode.removeChild(img);
  });

  const addTextOverflow = (html) => {
    const modifiedHTML = data.includes("Pegi")
      ? html
          .replace(
            /<p/g,
            '<div style="white-space: nowrap; color: white; overflow: hidden; text-overflow: ellipsis;"'
          )
          .replace(/<\/p>/g, "</div>")
      : html;
    return modifiedHTML;
  };

  const sanitizedHTML = addTextOverflow(doc.body.innerHTML);

  return (
    <div>
      <h3 dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
    </div>
  );
}

const Item = ({ data, promptGeneration, selectedPrompt, isKor }) => {
  return (
    <Box
      className={`single  ${selectedPrompt._id === data._id ? "active" : ""} ${
        data?.isPinned === "yes" ? "pinned" : ""
      } `}
      key={data}
      id="HALA"
      onClick={() => {
        promptGeneration(data);
      }}
      style={{
        borderColor: data?.appearance?.borderColor ?? "#e5e7eb",
        transitionDuration: ".15s",
        borderRadius: "16px!important",
        transitionTimingFunction: "cubic-bezier(.4,0,.2,1)",
        transitionProperty:
          "color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-backdrop-filter",
      }}
      _hover={{
        borderColor:
          `${data?.appearance?.hoverBorderColor}!important` ?? "#ddd",
        background:
          `${data?.appearance?.hoverBackgroundColor}!important` ?? "#fff",
        borderRadius: "16px!important",
        // boxShadow:
        //   'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgb(65, 33, 182) 0px 2px 0px 0px',
      }}
    >
      {((isKor && data?.toFreeKo) || (!isKor && data?.toFree)) && (
        <Badge
          ml="1"
          fontSize="15px"
          colorScheme="green"
          position={"absolute"}
          right={"15px"}
          top={"15px"}
          color={"#675AF2"}
          borderRadius={"20px"}
          paddingY={"8px"}
          paddingLeft={"20px"}
          paddingRight={"20px"}
          backgroundColor={"#f1f1f1"}
          textTransform={"capitalize"}
        >
          Free
        </Badge>
      )}

      <div className="single-thumb">
        <div className="left">
          <img src={data?.image ?? ""} alt="" />
        </div>
      </div>

      <div className="single-content">
        <div>
          <h2>{isKor ? data?.titleKr : data?.title}</h2>
          <MyComponent
            data={
              isKor ? (data?.messageKr ? data?.messageKr : "") : data?.message
            }
          />
          {/* <h3 dangerouslySetInnerHTML={{ __html: data?.message }} /> */}
        </div>
      </div>
    </Box>
  );
};

const ChatPagePrompt2 = ({
  promptGeneration,
  selectedPrompt,
  setMicModal,
  setSelectedPrompt,
  homepageModalClose,
  setVoiceModule,
  selectedVoice,
  setSelectedVoice,
}) => {
  const lang = useLocation();
  const isKor = lang?.pathname.includes("/ko");
  const [loading, setLoading] = useState(true);
  const [totalRow, setTotalRow] = useState(0);
  const [prompts, setPrompts] = useState([]);
  const history = useCustomHistory();
  const authData = useContext(AuthContext);
  const membership = authData?.authState?.membership;
  const configData = useContext(ConfigContext);
  const config = configData.config || {};
  const [page, SetPage] = useState(1);
  const [categories, setCategories] = useState();
  const [chatPagev, setChatPageV] = useState("chatpage");

  const [category, setCategory] = useState("");
  const [sort, setSort] = useState("");
  const [query, setQuery] = useState("");
  const [details, setDetails] = useState(false);
  const { i18n } = useTranslation();
  const dvc_768 = useMediaQuery("(max-width: 768px)");
  const dvc_425 = useMediaQuery("(max-width: 425px)");
  const [selectedFilter, setSelectedFilter] = useState("");
  const [modal, setModal] = useState("");
  const [assitModal, setAssitModal] = useState("");
  const [modalData, setModalData] = useState({});
  const sessionListProvider = useContext(SessionListContext);
  const { setPagi } = sessionListProvider;
  const setFilterSelected = (str) => setSelectedFilter(str);
  const { t } = useTranslation();

  const modalContextData = useContext(ModalContext);

  const [buttonColor, setButtonColor] = useState("rgb(22, 164, 128)");
  const [buttonHoverColor, setButtonHoverColor] = useState(
    "rgba(79, 218, 188, .8)"
  );
  useEffect(() => {
    if (process.env.REACT_APP_PLATHFORM === "Chingu") {
      setButtonColor("rgb(22, 164, 128)");
      // setButtonHoverColor("rgba(79, 218, 188, .8)");
    }

    if (process.env.REACT_APP_PLATHFORM === "Questron") {
      setButtonColor("#09D809");
      // setButtonHoverColor("#08a108");
    }

    if (process.env.REACT_APP_PLATHFORM === "DappStoreAi") {
      setButtonColor("#0060ff");
      // setButtonHoverColor("#0060ff");
    }
  }, []);

  let queryData =
    urlParser.parse(window.location.href, { parseQueryString: true }).query ||
    {};
  let queryvalue = queryData.mode || "";

  const columns =
    queryvalue === "voice"
      ? config?.global?.TILES_CONFIG?.value?.["chatpageVoice"]?.columns
      : config?.global?.TILES_CONFIG?.value?.["chatpage"]?.columns;
  const spacing =
    queryvalue === "voice"
      ? Number(config?.global?.TILES_CONFIG?.value?.["chatpageVoice"]?.spacing)
      : Number(config?.global?.TILES_CONFIG?.value?.["chatpage"]?.spacing);

  const [items, setItems] = useState({
    data: [],
    columns: columns ? columns : 2,
    margin: spacing ? spacing : 10,
    filter: "",
    height: false,
  });

  const [offSet, setOffset] = useState(
    queryvalue === "voice"
      ? Number(
          config?.global?.TILES_CONFIG?.value?.["chatpageVoice"]?.itemPerPage
        )
      : Number(config?.global?.TILES_CONFIG?.value?.["chatpage"]?.itemPerPage)
  );
  // const { i18n } = useTranslation()

  console.log(
    chatPagev,
    items,
    "chatPagev",
    config?.global?.TILES_CONFIG?.value?.["chatpageVoice"]?.columns
  );

  const fetchData = async () => {
    try {
      setLoading(true);
      let queryData =
        urlParser.parse(window.location.href, { parseQueryString: true })
          .query || {};
      let queryvalue = queryData.mode || "";

      let queryString = "";

      console.log(
        "membership",
        membership,
        authData?.authState?.isAuthenticated,
        authData?.isFetched
      );

      if (membership === "free" && i18n?.language === "en") {
        queryString = "&toPro=true";
      }

      if (membership === "free" && i18n?.language === "ko") {
        queryString = "&toProKo=true";
      }
      if (
        !authData?.authState?.isAuthenticated &&
        membership === "guest" &&
        i18n?.language === "en"
      ) {
        queryString = "&toGuest=true";
      }
      if (
        !authData?.authState?.isAuthenticated &&
        membership === "guest" &&
        i18n?.language === "ko"
      ) {
        queryString = "&toGuestKo=true";
      }

      if (membership === "pro" && i18n?.language === "en") {
        queryString = "&toPro=true";
      }
      if (membership === "pro" && i18n?.language === "ko") {
        queryString = "&toProKo=true";
      }
      if (category) {
        queryString += `&category=${category?.value}`;
      }
      if (selectedFilter) {
        queryString += `&category=${selectedFilter}`;
      }
      if (sort) {
        queryString += `&sortBy=createdAt&sortOrder=${sort?.value}`;
      }
      if (query) {
        queryString += `&query=${query}`;
      }

      if (queryvalue === "voice" && i18n?.language === "en") {
        queryString = `&toVoiceMode=true`;
      }

      if (queryvalue === "voice" && i18n?.language === "ko") {
        queryString = `&toVoiceModeKo=true`;
      }

      // if (queryvalue !== "voice" && i18n?.language === "en") {
      //   queryString += `&toChatMode=true`;
      // }

      // if (queryvalue !== "voice" && i18n?.language === "ko") {
      //   queryString += `&toChatModeKo=true`;
      // }

      const results = await userPublicRequest.get(
        `/get-prompts?page=${page}&limit=${offSet}&chatpage=true&source=admin${queryString}&promptType=${
          queryvalue === "voice" ? "" : ""
        }`
      );
      // /assistant

      const pagi = results?.data?.data?.docs?.find((rs) => {
        if (rs.promptType === "apps" && !rs.redirectionUrl) {
          return rs;
        }
      });

      setPagi(pagi);
      let docs = [...results?.data?.data?.docs];

      // if (queryvalue === "voice") {
      //   docs = docs.filter((dc) => {
      //     if (dc?.assistant?._id) {
      //       return dc;
      //     }
      //   });
      // }
      // if()
      console.log(docs, "queryvalue");

      setPrompts(docs);
      setItems({ ...items, data: docs });
      setLoading(false);
      setTotalRow(results?.data?.data?.totalDocs);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (authData?.isFetched) {
      fetchData(page);
    }
  }, [page, category, sort, selectedFilter, authData?.isFetched]);

  const fetchCategories = async () => {
    try {
      const results = await userPublicRequest.get(`/prompt-categories`);
      setCategories(results?.data?.data);
    } catch (err) {}
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  let columnsData = [];

  columnsData = useMemo(
    () =>
      categories?.map((cat) => {
        return {
          id: cat?._id,
          value: cat?._id,
          label: cat?.title,
          labelKr: cat?.titleKr,
        };
      }),
    [categories]
  );

  const isPagiEnabled = (data) => {
    if (data?.slides && data?.slides?.length > 0) {
      setModal(true);
      setModalData(data);
      return;
    }

    console.log("data?.promptType", data?.promptType);
    if (data?.promptType === "tts") {
      modalContextData.setCurrentModalName("ttsModal");
      modalContextData.setModalData({
        data,
        onClose: () => {
          modalContextData.setCurrentModalName(null);
          modalContextData.setModalData({});
        },
      });
      return;
    } else if (data.redirectionUrl) {
      window.open(`${data?.redirectionUrl}/${i18n.language}`);
    } else {
      setMicModal();
    }
  };

  const modalCloseHandler = (data) => {
    if (data.redirectionUrl) {
      window.open(`${data?.redirectionUrl}/${i18n.language}`);
    } else {
      setMicModal();
    }
    setModal(false);
    setModalData({});
  };

  const Cell = ({
    toggle,
    data,
    height,
    description,
    css,
    maximized,
    setSelectedPrompt,
    homepageModalClose,
  }) => {
    const messageRef = useRef(null);

    const shouldShow = () => {
      let modalDisabledDate = localStorage.getItem("modalDisabledDate");
      if (!modalDisabledDate) {
        return true;
      }
      return dayjs(modalDisabledDate) < dayjs();
    };

    useEffect(() => {
      let queryData =
        urlParser.parse(window.location.href, { parseQueryString: true })
          .query || {};
      let queryvalue = queryData.selectedShortcutPrompt || "";

      if (homepageModalClose && data?._id === queryvalue) {
        if (data.promptType === "apps") {
          isPagiEnabled(data);
        } else {
          toggleHandler();
          setSelectedPrompt(data);
        }
        history.replace("/chat");
      }
    }, [homepageModalClose]);

    useEffect(() => {
      const modifyAnchors = () => {
        const div = messageRef.current;

        if (div) {
          const anchors = div.getElementsByTagName("a");
          Array.from(anchors).forEach((a) => {
            let href = a.getAttribute("href");
            if (href) {
              if (!href.startsWith("http://") && !href.startsWith("https://")) {
                href = `http://${href}`;
              }
              a.setAttribute("href", href);
              a.style.color = "#0000EE";
              a.target = "_blank";
            }
          });
        }
      };

      modifyAnchors();
    }, []);

    const toggleHandler = () => {
      console.log(data, 375);
      if (queryvalue === "voice") {
        // Request audio permission from the user
        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then((stream) => {
            console.log("Audio permission granted");

            // If permission is granted, set up the voice module
            setVoiceModule(data);
            // setModalData(data);
            console.log(401, "selectedPrompt");
            setSelectedPrompt(data);
          })
          .catch((error) => {
            console.error("Audio permission denied:", error);
            // Handle the case where the user denies permission
            return; // Return from the function
          });
      } else if (data?.slides && data?.slides?.length > 0) {
        setModal(true);
        setSelectedPrompt(data);
        setModalData(data);
        return;
      } else {
        setSelectedPrompt(data);
        toggle();
      }
    };

    const modalRegularCloseHandler = () => {
      toggle();
      setModal(false);
      setModalData({});
    };

    const modalRegularCloseHandlerV = () => {
      console.log(412);
      // toggle();
      setAssitModal(false);
      setModalData({});
    };
    // useEffect(() => {
    //   if(!selectedPrompt?._id) {
    //     promptGeneration(data);
    //     toggle();
    //     setDetails(false);
    //   }
    // }, [selectedPrompt])

    // // console.log(details, selectedPrompt,  data?.title, 'maximized')
    return (
      <>
        {modal && data._id === modalData?._id && (
          <PromptModal
            modalData={modalData}
            modalCloseHandler={
              data.promptType === "apps"
                ? modalCloseHandler
                : modalRegularCloseHandler
            }
            closeModal={() => setModal(false)}
          />
        )}

        {/* {assitModal && data._id === modalData?._id && (
          <AssistantModal
            modalData={modalData}
            modalCloseHandler={
              data.promptType === "apps"
                ? modalCloseHandler
                : modalRegularCloseHandlerV
            }
            submitHandler={submitHandler}
            closeModal={() => setModal(false)}
          />
        )} */}

        {data.promptType === "apps" || data.promptType === "tts" ? (
          <Box
            className={`single agi p-${data?._id} ${
              selectedPrompt._id === data._id ? "active" : ""
            } ${data?.isPinned === "yes" ? "pinned" : ""}   ${
              !data?.isPagi === "yes" ? "pagi-active" : ""
            } `}
            key={data?._id}
            onClick={() => {
              let exceptionMessage = isKor
                ? config?.global?.MODULE_EXCEPTION?.value
                    ?.proModuleEligibilityMessageKo
                : config?.global?.MODULE_EXCEPTION?.value
                    ?.proModuleEligibilityMessageEn;
              let freeStatus = isKor ? data?.toFreeKo : data?.toFree;
              if (membership === "free" && !freeStatus) {
                // toast(exceptionMessage, {
                //   position: toast.POSITION.BOTTOM_RIGHT,
                // });
                modalContextData.setCurrentModalName(
                  "moduleEligibilityException"
                );
              } else {
                isPagiEnabled(data);
              }
            }}
            style={{
              borderColor: data?.appearance?.borderColor ?? "#e5e7eb",
              backgroundColor:
                data?.appearance?.backgroundColor ?? "transparent",
              transitionDuration: ".15s",
              borderRadius: "16px!important",
              transitionTimingFunction: "cubic-bezier(.4,0,.2,1)",
              transitionProperty:
                "color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-backdrop-filter",
            }}
            _hover={{
              borderColor:
                `${data?.appearance?.hoverBorderColor}!important` ?? "#ddd",
              background:
                `${data?.appearance?.hoverBackgroundColor}!important` ?? "#fff",
              borderRadius: "16px!important",
              // boxShadow:
              //   'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgb(65, 33, 182) 0px 2px 0px 0px',
            }}
          >
            {((isKor && data?.toFreeKo) || (!isKor && data?.toFree)) && (
              <Badge
                ml="1"
                fontSize="15px"
                colorScheme="green"
                position={"absolute"}
                right={"15px"}
                top={"15px"}
                color={"#675AF2"}
                borderRadius={"20px"}
                paddingY={"8px"}
                paddingLeft={"20px"}
                paddingRight={"20px"}
                backgroundColor={"#f1f1f1"}
                textTransform={"capitalize"}
              >
                Free
              </Badge>
            )}
            <div className="single-thumb">
              <div className={` left`}>
                <img src={data?.image ?? ""} alt="" />
              </div>

              {data?.isPagi !== "yes" && (
                <div
                  className={`${
                    data?.isPinned === "yes" ? "right active " : "right"
                  }`}
                >
                  <BsPin size={14} />
                </div>
              )}

              {data?.sourceBadge && (
                <div className="agi-tag">{data?.sourceBadge}</div>
              )}
            </div>

            <div className="single-content e">
              <div>
                <h2>{isKor ? data?.titleKr : data?.title}</h2>
                <MyComponent
                  data={
                    isKor
                      ? data?.messageKr
                        ? data?.messageKr
                        : ""
                      : data?.message
                  }
                />
              </div>
            </div>
          </Box>
        ) : (
          <Box
            className="cell "
            style={{
              cursor: !maximized ? "pointer" : "auto",
              background: data?.appearance?.backgroundColor || "transparent",
              marginLeft: maximized ? "0px" : "0px",
              borderColor: data?.appearance?.borderColor ?? "#e5e7eb",
              borderRadius: "16px",
            }}
            onClick={!maximized ? toggleHandler : undefined}
          >
            <Fade show={maximized} delay={maximized ? 500 : 0}>
              <div className="detailss single" style={{ overflowY: "auto" }}>
                <Slug delay={600}>
                  <div className="single-thumb">
                    <div className="left">
                      <img src={data?.image ?? ""} alt="" />
                    </div>
                  </div>

                  <div className="single-content">
                    <div>
                      <h2>{isKor ? data?.titleKr : data?.title} </h2>
                      <div
                        ref={messageRef}
                        dangerouslySetInnerHTML={{
                          __html: isKor
                            ? data?.messageKr
                              ? data?.messageKr
                              : ""
                            : data?.message,
                        }}
                      />
                    </div>
                  </div>
                  {/* <div
                    className="closeIcon"
                    onClick={() => {
                      promptGeneration(data)
                      toggle()
                    }}
                  >
                    <AiOutlineClose color="#777777" size={22} />
                  </div> */}
                  <div
                    className="backIcon"
                    onClick={() => {
                      promptGeneration(data);
                      toggle();
                      setDetails(false);
                    }}
                  >
                    <FiArrowLeft color="#777777" size={22} />
                  </div>
                </Slug>
              </div>
            </Fade>

            <Fade
              show={!maximized}
              from={{ opacity: 0, transform: "translate3d(0,140px,0)" }}
              enter={{ opacity: 1, transform: "translate3d(0,0px,0)" }}
              leave={{ opacity: 0, transform: "translate3d(0,-50px,0)" }}
              delay={maximized ? 0 : 400}
            >
              <div className="default" onClick={() => setDetails(true)}>
                <Item
                  data={data}
                  promptGeneration={promptGeneration}
                  selectedPrompt={selectedPrompt}
                  isKor={isKor}
                />
              </div>
            </Fade>
          </Box>
        )}
      </>
    );
  };
  return (
    <div
      className={`pagi_filter_chatPrompt_wrapper ${
        selectedPrompt?._id ? "details-active" : ""
      }`}
    >
      <NativeMeClone config={config} />
      <div className="pagi_filter_chatPrompt">
        <ul
          className="fiters"
          style={{
            marginBottom: `${spacing}px`,
          }}
        >
          <li
            onClick={() => setFilterSelected("")}
            className={`${selectedFilter === "" ? "active" : " "}`}
          >
            {t("All")}
          </li>
          {columnsData?.map((sm, i) => (
            <li
              key={sm?.id}
              onClick={() => setFilterSelected(sm?.value)}
              className={`${selectedFilter === sm?.value ? "active" : ""}`}
            >
              {isKor ? sm?.labelKr : sm?.label}
            </li>
          ))}
        </ul>

        <div
          className={`arm_clone  ${
            selectedPrompt?._id ? "details-active" : ""
          }`}
          style={{ backgroundColor: "#f9fafb" }}
        >
          {!loading && (
            <>
              <Grid
                className={`grid ${details ? "details-active" : ""}`}
                data={items?.data}
                keys={(d) => d._id}
                heights={items.height ? (d) => d.height : 255}
                columns={dvc_768 ? 1 : Number(items?.columns)}
                margin={Number(items?.margin)}
                id="GRID"
                lockScroll={true}
                // closeDelay={400}
              >
                {(data, maximized, toggle) => (
                  <Cell
                    data={data}
                    setSelectedPrompt={setSelectedPrompt}
                    maximized={maximized}
                    toggle={() => {
                      // toggle();

                      let freeStatus = isKor ? data?.toFreeKo : data?.toFree;
                      let exceptionMessage = isKor
                        ? config?.global?.MODULE_EXCEPTION?.value
                            ?.proModuleEligibilityMessageKo
                        : config?.global?.MODULE_EXCEPTION?.value
                            ?.proModuleEligibilityMessageEn;
                      if (membership === "free" && !freeStatus) {
                        // toast(exceptionMessage, {
                        //   position: toast.POSITION.BOTTOM_RIGHT,
                        // });

                        modalContextData.setCurrentModalName(
                          "moduleEligibilityException"
                        );
                      } else {
                        toggle();
                      }
                    }}
                    className="checking"
                    homepageModalClose={homepageModalClose}
                  />
                )}
              </Grid>
            </>
          )}
        </div>
        {!selectedPrompt?._id && (
          <ChatPromtPagination
            limit={offSet}
            page={page}
            totalRow={totalRow}
            handlePageChange={(limit, newOffset, page) => {
              SetPage(page);
            }}
          />
        )}
      </div>

      <style jsx>{`
        .single.pinned {
          // border: 1px solid #b07f43;
        }
        .single.agi .single-thumb {
          position: relative;
        }
        .agi-tag {
          position: absolute;
          left: 55px;
          bottom: 0px;
          background: #9672bb;
          color: #fff;
          font-size: 12px;
          line-height: 14px;
          font-weight: 600;
          width: 40px;
          height: 40px;
          border-radius: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          // padding: 5px 15px;
          // border-radius: 4px 16px 4px 4px;
        }
        .fiters {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
        }
        .fiters li:hover,
        .fiters li.active {
          background: ${buttonColor};
          color: #fff;
        }
        .fiters li {
          list-style: none;
          font-size: 14px;
          line-height: 16px;
          padding: 5px 14px;
          margin-right: 8px;
          margin-bottom: 0px;
          border-radius: 20px;
          font-family: "Noto Sans", sans-serif;
          cursor: pointer;
        }
        .details_anim {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 10px;
        }
        .details_content,
        .details-image {
          margin-top: auto;
        }
        .details-image img {
          width: auto;
          border-radius: 8px;
          margin: auto;
          max-height: 400px;
        }
        .arm_clone.details-active {
          margin-top: inherit;
          margin-bottom: inherit;
          height: 100%;
        }
        .pagi_filter_chatPrompt_wrapper.details-active {
          padding-bottom: 20px;
        }

        .search-btn {
          padding: 10px;
          background: #f4f4f5;
          height: 46px;
          border-radius: 8px;
          margin-left: ${items?.margin + 10}px;
        }
        .search-input-area {
          border: 1px solid #ccc;
          height: 46px;
          padding: 10px;
          border-radius: 4px;
          width: 400px;
        }
        .search-input-area::placeholder {
          color: hsl(0, 0%, 50%); /* Placeholder text color */
        }
        .pagi_filter_chatPrompt_wrapper {
          position: relative;
          height: 100%;
          padding-top: 30px;
        }
        .cell {
          height: 100%;
          // background: #bbb;
        }
        .pagi_filter_chatPrompt {
        }

        .grid {
          z-index: 0;
          margin: -${!selectedPrompt?._id ? Number(items?.margin) : 0}px;

          width: calc(
            100% + ${!selectedPrompt?._id ? Number(items?.margin * 2) : 0}px
          ) !important;
          overflow-x: hidden !important;
        }
        .filters_all {
          position: absolute;
          display: flex;
          align-items: center;
          gap: ${items?.margin}px;
          // margin-left: ${Number(items?.margin)}px;
          // margin-right: ${items?.margin}px;
          top: 20px;
        }

        .arm_clone {
          width: 100%;
          max-width: 100%;
          height: auto;
          margin-top: auto;
          margin-bottom: auto;
          overflow: hidden;
          position: relative;
        }
        .griti {
          padding-top: 16px;
          padding-bottom: 16px;
          display: grid;
          gap: 8px;
          grid-template-columns: repeat(2, minmax(0, 1fr));
        }
        .single.active {
          // background: #ccc8c8ba;
        }
        .single {
          padding: 40px 24px 24px 24px;
          cursor: pointer;
          width: 100%;
          height: 100%;
          border-radius: 16px;
          // box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px, rgb(229, 231, 235) 0px 0px 0px 1px, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
          border: 1px solid #e5e7eb;
          transition: 0.3 linear;
        }
        // .single:hover .single-thumb > .right {
        //   opacity: 0.6;
        // }
        .single-thumb > .right.active svg {
          fill: #16a480;
        }
        .single-thumb > .right.active {
          opacity: 1;
          width: 30px;
          height: 30px;
          background: #16a48030;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .single-thumb > .right {
          opacity: 0;
        }
        .single-thumb > .left {
          width: 64px;
          height: 64px;
          background: transparent;
          // border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .single-thumb .left img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          // border-radius: 50%;
        }
        .single-thumb {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          margin-bottom: 1.5rem;
        }
        .single-thumb img {
          width: 100%;
          object-fit: cover;
          object-position: top center;
          border-radius: 8px;
          height: 100%;
        }
        .single-content {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          // height: calc(100% - 74px);
          overflow: hidden;
        }
        .details_content h2,
        .single h2 {
          color: #27272a;
          font-weight: 500;
          font-size: 1.125rem;
          line-height: 1.75rem;
          margin-bottom: 10px;
          font-family: "Noto Sans", sans-serif;
        }
        .details_content h3,
        .single h3 {
          color: #707078;
          font-weight: 500;
          font-size: 1rem;
          line-height: 1.5rem;
          margin-bottom: 0px;
          -webkit-line-clamp: 2;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
        }
        .single .macro-text {
          display: flex;
          align-items: center;
          gap: 0px 4px;
          margin-bottom: 10px;
          min-height: 24px;
        }
        .single .macro-text .icon {
        }
        .single .macro-text .icon svg {
          color: #9b9ca9;
          fill: #9b9ca9;
          stroke: #9b9ca9;
        }
        .closeIcon {
          position: absolute;
          right: 10px;
          top: 10px;
          z-index: 9;
          cursor: pointer;
        }
        .backIcon {
          position: absolute;
          left: 10px;
          top: 10px;
          z-index: 9;
          cursor: pointer;
        }
        .single .macro-text p {
          color: #707078;
          font-weight: 400;
          font-size: 0.875rem;
          line-height: 1.5rem;
          margin-bottom: 0px;
          display: flex;
          align-items: center;
          gap: 0px 4px;
        }
        .single .macro-text span {
        }
        // .uppercase {
        //   text-transform: uppercase;
        // }

        @media (max-width: 1280px) {
          .pagi_filter_chatPrompt_wrapper {
            padding-top: 20px;
          }
        }
        @media (max-width: 1260px) {
          .filters_all {
            flex-wrap: wrap;
          }
        }
        @media (max-width: 1024px) {
          .griti {
            padding: 0px 50px;
          }
          .filters_all {
            position: static;
            margin-bottom: 20px;
          }
        }
        @media (max-width: 920px) {
          .griti {
            display: grid;
            gap: 8px;
            padding: 0px 50px;
            grid-template-columns: repeat(1, minmax(0, 1fr));
          }
          .arm_clone {
            overflow-x: hidden;
            overflow-y: hidden;
            display: block;
          }
        }

        @media (max-width: 884px) {
          .details {
          }
          .arm_clone.details-active {
            height: calc(100% - 105px);
          }
          .search_bar_area {
            width: 100%;
          }
          .search_bar_area form {
            display: flex;
          }
          .search-input-area {
            width: calc(100% - 81px);
          }
          .griti {
            display: grid;
            gap: 8px;
            grid-template-columns: repeat(1, minmax(0, 1fr));
          }
          .grid {
            // padding-top: 40px;
          }
        }
        @media (max-width: 768px) {
          // .details .icon{
          //   top: 10px;
          // }
          .arm_clone.details-active {
            height: calc(100% - 163px);
          }
          .pagi_filter_chatPrompt_wrapper {
            padding-top: 20px;
          }
          .dropdown {
            width: 100%;
          }
        }
        @media (max-width: 536px) {
          .griti {
            padding: 0px 30px;
          }
        }
        @media (max-width: 340px) {
          .single {
            padding: 20px 15px;
          }
        }
      `}</style>

      <style jsx>{`
        .details {
          position: absolute;
          bottom: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          background: #fff;
          color: white;
          font-weight: 100;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
        }

        .details p {
          color: thistle;
          font-size: 26px;
          line-height: 26px;
          margin: 0;
          padding-top: 15px;
        }
        .cell {
          position: relative;
          background-size: cover;
          background: #fff;
          width: 100%;
          height: 100%;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .default {
          position: absolute;
          width: 100%;
          left: 0;
          top: 0;
          height: 100%;
          // display: flex;
          // align-items: flex-end;
          // justify-content: center;
        }

        .cell:hover {
          // box-shadow: 0px 20px 60px -10px rgba(0, 0, 0, 0.3);
        }
      `}</style>
    </div>
  );
};

export default React.memo(ChatPagePrompt2);
