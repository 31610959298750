import {
  Box,
  Flex,
  IconButton,
  Input,
  Text,
  useDisclosure,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Select,
} from "@chakra-ui/react";
import { userPrivateRequest } from "config/axios.config";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import v from "voca";
import useCustomHistory from "langHoc/useCustomHistory";
import { SessionListContext } from "contexts/SessionListContext";
import { FaPlay, FaPause } from "react-icons/fa";

const TtsModal = ({ onClose, data }) => {
  const [voices, setVoices] = useState([]);
  const [audioPlayer, setAudioPlayer] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    userPrivateRequest.get("/elevenLabs/voices").then((res) => {
      setVoices(res?.data?.voices);
    });
  }, []);
  const history = useCustomHistory();

  const sessionListProvider = useContext(SessionListContext);

  const { selectedVoice, setSelectedVoice } = sessionListProvider;

  const handlePreviewPlay = () => {
    if (audioPlayer) {
      if (isPlaying) {
        audioPlayer.pause();
        setIsPlaying(false);
      } else {
        audioPlayer.play();
        setIsPlaying(true);
      }
    } else if (selectedVoice?.preview_url) {
      const audio = new Audio(selectedVoice.preview_url);
      audio.addEventListener('ended', () => setIsPlaying(false));
      audio.play();
      setAudioPlayer(audio);
      setIsPlaying(true);
    }
  };

  const handleVoiceChange = (e) => {
    if (audioPlayer) {
      audioPlayer.pause();
      setAudioPlayer(null);
      setIsPlaying(false);
    }
    
    let id = e.target.value;
    let voice = voices?.find((el) => el.id === id);
    setSelectedVoice(voice);
  };

  return (
    <Modal id="SHARE_MODAL" onClose={onClose} isOpen={true} isCentered={true}>
      <ModalOverlay
        backdropFilter={"blur(2px) opacity(0.9) hue-rotate(20deg)"}
      />
      <ModalContent
        borderRadius={"20px"}
        boxShadow="rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px"
        my="auto"
        w="800px"
        maxWidth={"500px"}
      >
        <ModalCloseButton
          autoFocus={false}
          bg="transparent"
          top="16px"
          right="16px"
          _focus={{
            boxShadow: "none",
          }}
        />
        <ModalBody padding="16px 24px 16px 24px">
          <Box>
            <ModalHeader pl="0">{data?.title ?? " TTS "}</ModalHeader>
            <form>
              <FormControl>
                <FormLabel
                  fontSize="sm"
                  fontWeight="500"
                  color={"#000"}
                  mb="10px"
                >
                  Voice
                </FormLabel>
                <Select
                  onChange={handleVoiceChange}
                  placeholder="Select voice"
                  mb="10px"
                >
                  {voices?.map((el) => {
                    return (
                      <option value={el.id}>{v.titleCase(el.name)}</option>
                    );
                  })}
                  {/* Add other voice options as needed */}
                </Select>
                {selectedVoice && (
                  <Flex mb="20px" direction="column">
                    {selectedVoice?.description && (
                      <Text fontSize="sm" mr="10px" mb="10px" color="#8F9BBA" textAlign="justify">
                        {selectedVoice?.description}
                      </Text>
                    )}
                    <Box>
                      <Flex align="center">
                        <Text fontSize="sm" mr="10px">
                          Preview voice :
                        </Text>
                        <IconButton
                          icon={isPlaying ? <FaPause /> : <FaPlay />}
                          aria-label={isPlaying ? "Pause" : "Play"}
                          size="sm"
                          variant="outline"
                          onClick={handlePreviewPlay}
                          isDisabled={!selectedVoice?.preview_url}
                          colorScheme="green"
                        />
                      </Flex>
                    </Box>
                  </Flex>
                )}
                <Flex>
                  <Button
                    fontWeight="700"
                    color="#fff"
                    bg="#4FDABC"
                    py="5px"
                    px="25px"
                    borderRadius="10px"
                    width="110px"
                    _hover={{
                      bg: "rgba(79, 218, 188, .8)",
                    }}
                    _active={{
                      bg: "#4FDABC",
                    }}
                    _focus={{
                      bg: "#4FDABC",
                    }}
                    onClick={() => {
                      if (audioPlayer) {
                        audioPlayer.pause();
                        setAudioPlayer(null);
                        setIsPlaying(false);
                      }
                      setSelectedVoice(null);
                    }}
                    mr="20px"
                  >
                    Back
                  </Button>
                  <Button
                    fontWeight="700"
                    color="#fff"
                    bg="#4FDABC"
                    py="5px"
                    px="25px"
                    borderRadius="10px"
                    width="110px"
                    _hover={{
                      bg: "rgba(79, 218, 188, .8)",
                    }}
                    _active={{
                      bg: "#4FDABC",
                    }}
                    _focus={{
                      bg: "#4FDABC",
                    }}
                    onClick={() => {
                      onClose();
                      history.replace(
                        `/chat?selectedShortcutPrompt=${data?._id}`
                      );
                    }}
                  >
                    Generate
                  </Button>
                </Flex>
              </FormControl>
            </form>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default TtsModal;
